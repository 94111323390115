<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="800px" @close="close">
    <div class="flexBox">
      {{ form.id ? `您已完成授权，粉丝数据会在每日零点进行更新。` : `使用此功能，您需要填写公众号的appid和appsecret，并且提供您 公众号的校验文件，配置白名单。`}}

      <el-popover placement="right" :width="400" trigger="hover">
        <template #reference>
          <el-tag style="cursor: pointer;"> 如有问题，微信扫一扫联系客服</el-tag>
        </template>
        <el-image src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-mini/xx.jpg"></el-image>
      </el-popover>

      <div v-if="form.id" style="color: #999;font-size:12px;">
        *同步完成时间依据粉丝数量决定，粉丝数量越多，同步时间越长
      </div>
    </div>

    <el-form style="margin-top: 20px" label-width="200" :rules="rules" :model="form" ref="formRef">
      <el-form-item label="Appid" prop="appid">
        <el-input v-model="form.appid" style="width: 240px" placeholder="请填写公众号的appid" />
      </el-form-item>
      <el-form-item label="AppSecret" prop="secret">
        <el-input v-model="form.secret" style="width: 240px" placeholder="请填写公众号的secret" />
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">
        {{ form.id ? '修改配置' : '保存配置'}}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  toRefs,
  ref,
  onMounted,
} from 'vue'
import {
  enterPriseUserSaveWechatConfig,
  enterPriseUserUpdateWechatConfig,
  enterPriseUserSaveWxUser,
} from '@/api/enterPriseApi'
// 引入下拉数选择
export default defineComponent({
  name: 'importWeChat',
  components: {},
  props: {
    eventId: {
      type: String,
      default: '',
    },
  },
  emits: ['fetch-data'],
  setup() {
    const { proxy } = getCurrentInstance()
    // 自定义手机验证
    const subLabel = ref('选择展会')
    const state = reactive({
      formRef: null,
      upLoadBox: null,
      form: {
        appid: '',
        secret: '',
      },
      identityType: '',
      file: null,
      fileList: [],
      departmentData: [],
      rules: {
        appid: [{ required: true, trigger: 'blur', message: '请输入appid' }],
        secret: [
          { required: true, trigger: 'blur', message: '请输入appscret' },
        ],
      },
      title: '',
      dialogFormVisible: false,
      roleList: [],
      // HOLD_MEETING EXHIBITION FORUM
      exhList: [
        {
          label: '会议',
          value: 'HOLD_MEETING',
        },
        {
          label: '展会',
          value: 'EXHIBITION',
        },
        {
          label: '论坛',
          value: 'FORUM',
        },
      ],
      subExhList: [],
      identityList: [],
      meetingCode: '', // 二级菜单传递code
      meetingType: '', // 一级菜单传递type
      upLoading: false,
    })
    const showEdit = async (row) => {
      if (row) {
        state.form = row
      }
      state.title = '公众号粉丝同步'
      state.dialogFormVisible = true
    }

    const close = () => {
      state.form = {}
      state.dialogFormVisible = false
    }
    const save = async () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          if (state.form.id) {
            const { data } = await enterPriseUserUpdateWechatConfig(state.form)
            proxy.$baseConfirm(
              '您已完成粉丝授权，正在同步公众号粉丝。若您的公众号粉丝数量很多，则同步时间可能较长，请耐心等待。同步完成后，每日零点会同步更新 新增关注和取消关注的粉丝信息。',
              `同步公众号粉丝`,
              async () => {
                proxy.$baseMessage(
                  `操作成功`,
                  'success',
                  'vab-hey-message-success'
                )
                enterPriseUserSaveWxUser()
                close()
              },
              () => {},
              `我知道了`,
              ``
            )
          } else {
            const { data } = await enterPriseUserSaveWechatConfig(state.form)
            proxy.$baseConfirm(
              '您已完成粉丝授权，正在同步公众号粉丝。若您的公众号粉丝数量很多，则同步时间可能较长，请耐心等待。同步完成后，每日零点会同步更新 新增关注和取消关注的粉丝信息。',
              `同步公众号粉丝`,
              async () => {
                proxy.$baseMessage(
                  `操作成功`,
                  'success',
                  'vab-hey-message-success'
                )
                enterPriseUserSaveWxUser()
                close()
              },
              () => {},
              `我知道了`,
              ``
            )
          }
        }
      })
    }

    onMounted(() => {})

    return {
      ...toRefs(state),
      showEdit,
      close,
      save,
    }
  },
})
</script>

<style lang="scss" scoped>
</style>
